import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Digital kiosks" titleElement="h3" linkText="Read the digital kiosks guidance" linkTo="/experiences/digital-kiosks" border mdxType="GridListItem">
    How to design accessible digital kiosk experiences.
  </GridListItem>
    </GridList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      